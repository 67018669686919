<template>
    <div class="body-bg">
        <page-navigate :blankType="true"></page-navigate>
        <div :class="[$t('common.requestLang'), $t('common.type'), $t('common.fontFamily'), $t('common.lang'),]">
            <section class="section1">
                <div class="section1-bg">
                    <img class="section1-logo" src="../assets/image/refer-friends/ebc_logo.svg" alt="">
                    <h1 :class="$t('common.fontFamilyTitle')">成为<span class="ebc">EBC</span>推荐官 与我们巴萨见 </h1>
                    <h3><span class="ebc">EBC</span>邀您共赴红蓝之约</h3>
                    <a href="" class="refer-friends-btn">
                        立即参赛
                    </a>
                </div>
            </section>
            <section class="section2">
                <img class="section2-300" src="../assets/image/refer-friends/$300.svg" alt="">
                <h2 :class="$t('common.fontFamilyTitle')">推荐好友获<span class="number">300</span>美金/人 </h2>
                <p class="p1">
                    您的每一次推荐，都是向传奇致敬的进球。即刻成为EBC推荐官，分享EBC最高获300美金/人，上不封顶。<br />
                    周周上榜，奖励无限，跻身赛季榜单，与EBC相约巴萨看球。 
                </p>
            </section>
            <section class="section3">
                <div class="title-box">
                    <img class="title-yezi" src="../assets/image/refer-friends/left_yezi.svg" alt="">
                    <div class="title-inner-box">
                        <h2 :class="$t('common.fontFamilyTitle')">荣誉成就榜</h2>
                        <p class="p1">
                            累计推荐
                        </p>
                    </div>
                    <img class="title-yezi" src="../assets/image/refer-friends/right_yezi.svg" alt="">
                </div>
                <div class="section3-card-list">
                    <div class="section3-card-bg">
                        <div class="section3-card section3-card1">
                            <h3 :class="$t('common.fontFamilyTitle')">
                                领袖奖
                            </h3>
                            <p class="p1">
                                巴塞罗那足球俱乐部联名足球
                            </p>
                            <p class="p2">
                                推荐27人，象征27次西班牙顶级联赛冠军
                            </p>
                        </div>
                    </div>
                    <div class="section3-card-bg">
                        <div class="section3-card section3-card2">
                            <h3 :class="$t('common.fontFamilyTitle')">
                                明星奖
                            </h3>
                            <p class="p1">
                                巴塞罗那足球俱乐部官方球衣
                            </p>
                            <p class="p2">
                                推荐78人，象征球队78次国内冠军头衔
                            </p>
                        </div>
                    </div>
                    <div class="section3-card-bg">
                        <div class="section3-card section3-card3">
                            <h3 :class="$t('common.fontFamilyTitle')">
                                传奇奖
                            </h3>
                            <p class="p1">
                                前往巴塞罗那享受VIP观赛体验
                            </p>
                            <p class="p2">
                                推荐125人，见证建队125周年特别场次
                            </p>
                        </div>
                    </div>
                </div>

                <div class="section3-line-list">
                    <div class="section3-line-box">
                        <div class="section3-line-title-box">
                            <div class="section3-line-title">
                                推荐开始
                            </div>
                            <div class="section3-line-count">
                                推荐结束
                            </div>
                        </div>
                        <div class="section3-line-bg-box">
                            <div class="section3-line-value">

                            </div>
                        </div>
                        <div class="section3-line-time-box">
                            <span class="section3-start-time">
                                2025.2.22
                            </span>
                            <span class="section3-end-time">
                                2025.5.23
                            </span>
                        </div>
                    </div>
                </div>
                <div class="section3-block-list">
                    <div class="section3-block-item">
                        <div class="section3-block-title cn-font-family-title">12人</div>
                        <p class="section3-block-p">领袖奖累计达成</p>
                    </div>
                    <div class="section3-block-item">
                        <div class="section3-block-title cn-font-family-title">8人</div>
                        <p class="section3-block-p">明星奖累计达成</p>
                    </div>
                    <div class="section3-block-item">
                        <div class="section3-block-title cn-font-family-title">1人</div>
                        <p class="section3-block-p">传奇奖累计达成</p>
                    </div>
                </div>
            </section>
            <section class="section4">
                <div class="title-box">
                    <img class="title-yezi" src="../assets/image/refer-friends/left_yezi.svg" alt="">
                    <div class="title-inner-box">
                        <h2 :class="$t('common.fontFamilyTitle')">榜单冠军</h2>
                    </div>
                    <img class="title-yezi" src="../assets/image/refer-friends/right_yezi.svg" alt="">
                </div>

                <div class="section4-card-list">
                    <div class="section4-card-bg">
                        <div class="section4-card section4-card1">
                            <h3 :class="$t('common.fontFamilyTitle')">
                                小吉奖
                            </h3>
                            <p class="p1">
                                巴塞罗那足球俱乐部联名足球
                            </p>
                            <p class="p2">
                                每周通过“邀请链接”激活人数最多者
                            </p>
                            <p class="p3">
                                周榜冠军
                            </p>
                        </div>
                    </div>
                    <div class="section4-card-bg">
                        <div class="section4-card section4-card2">
                            <h3 :class="$t('common.fontFamilyTitle')">
                                中吉奖
                            </h3>
                            <p class="p1">
                                巴塞罗那足球俱乐部周边礼包
                            </p>
                            <p class="p2">
                                每月通过“邀请链接”激活人数最多者
                            </p>
                            <p class="p3">
                                月榜冠军
                            </p>
                        </div>
                    </div>
                    <div class="section4-card-bg">
                        <div class="section4-card section4-card3">
                            <h3 :class="$t('common.fontFamilyTitle')">
                                大吉奖
                            </h3>
                            <p class="p1">
                                前往巴塞罗那享受VIP观赛体验
                            </p>
                            <p class="p2">
                                当赛季通过“邀请链接”激活人数最多者
                            </p>
                            <p class="p3">
                                赛季总冠军
                            </p>
                        </div>
                    </div>
                </div>

                <div class="section4-table-box">
                    <div class="section4-tabs">
                        <div @click="handleSelect(0)" :class="{'activied':activiedIndex == 0}" class="section4-tab cn-font-family-title ">
                            周榜冠军 
                        </div>
                        <div @click="handleSelect(1)" :class="{'activied':activiedIndex == 1}" class="section4-tab cn-font-family-title">
                            每月榜首
                        </div>
                        <div @click="handleSelect(2)" :class="{'activied':activiedIndex == 2}" class="section4-tab cn-font-family-title">
                            总榜首
                        </div>
                    </div>
                    <div class="section4-table">
                        <div class="section4-table-week-list">
                            <div class="section4-table-week-item">
                                <img class="section4-jp1" src="../assets/image/refer-friends/section4-jp1.png" alt="">
                                <img class="section4-jp2" src="../assets/image/refer-friends/section4-jp2.png" alt="">
                                <p class="p1">
                                    第一周
                                </p>
                                <p class="p2">
                                    User888
                                </p>
                            </div>

                            <div class="section4-table-week-item">
                                <img class="section4-jp1" src="../assets/image/refer-friends/section4-jp1.png" alt="">
                                <img class="section4-jp2" src="../assets/image/refer-friends/section4-jp2.png" alt="">
                                <p class="p1">
                                    第二周
                                </p>
                                <p class="p2">
                                    User888
                                </p>
                            </div>

                            <div class="section4-table-week-item">
                                <img class="section4-jp1" src="../assets/image/refer-friends/section4-jp1.png" alt="">
                                <img class="section4-jp2" src="../assets/image/refer-friends/section4-jp2.png" alt="">
                                <p class="p1">
                                    第三周
                                </p>
                                <p class="p2">
                                    User888
                                </p>
                            </div>

                            <div class="section4-table-week-item">
                                <img class="section4-jp1" src="../assets/image/refer-friends/section4-jp1.png" alt="">
                                <img class="section4-jp2" src="../assets/image/refer-friends/section4-jp2.png" alt="">
                                <p class="p1">
                                    第四周
                                </p>
                                <p class="p2">
                                    User888
                                </p>
                            </div>

                            <div class="section4-table-week-item">
                                <img class="section4-jp1" src="../assets/image/refer-friends/section4-jp1.png" alt="">
                                <img class="section4-jp2" src="../assets/image/refer-friends/section4-jp2.png" alt="">
                                <p class="p1">
                                    第五周
                                </p>
                                <p class="p2">
                                    User888
                                </p>
                            </div>

                            <div class="section4-table-week-item">
                                <img class="section4-jp1" src="../assets/image/refer-friends/section4-jp1.png" alt="">
                                <img class="section4-jp2" src="../assets/image/refer-friends/section4-jp2.png" alt="">
                                <p class="p1">
                                    第六周
                                </p>
                                <p class="p2">
                                    User888
                                </p>
                            </div>

                            <div class="section4-table-week-item">
                                <img class="section4-jp1" src="../assets/image/refer-friends/section4-jp1.png" alt="">
                                <img class="section4-jp2" src="../assets/image/refer-friends/section4-jp2.png" alt="">
                                <p class="p1">
                                    第七周
                                </p>
                                <p class="p2">
                                    User888
                                </p>
                            </div>
                        </div>
                        <div class="section4-tips">
                            公平参与承诺：禁止多账号刷量等违规行为，违规者取消资格！
                        </div>
                    </div>
                </div>
            </section>
            <section class="section5">
                <div class="title-box">
                    <img class="title-yezi" src="../assets/image/refer-friends/left_yezi.svg" alt="">
                    <div class="title-inner-box">
                        <h2 :class="$t('common.fontFamilyTitle')">活动规则</h2>
                    </div>
                    <img class="title-yezi" src="../assets/image/refer-friends/right_yezi.svg" alt="">
                </div>
                <div class="rule-box">
                    <div class="rule-item">
                        <div class="rule-label">如何成为推荐官？</div>
                        <div class="rule-value">
                            步骤1: 登录 EBC 用户中心
                        </div>
                        <div class="rule-value">
                            步骤2: 进入"金牌推荐官"活动页
                        </div>
                        <div class="rule-value">
                            步骤3: 生成专属邀请链接/二维码
                        </div>
                        <div class="rule-value">
                            步骤4: 好友通过链接注册并完成：入金≥$500 + 真实交易 1 笔
                        </div>
                    </div>
                    <div class="rule-item">
                        <div class="rule-label">奖励发放规则</div>
                        <div class="rule-value">
                            1. 周/月榜奖励：次周一 10:00（MT4 时间）发放至用户中心“我的奖品”
                        </div>
                        <div class="rule-value">
                            2. 成就奖励：达标后 48 小时内自动解锁
                        </div>
                        <div class="rule-value">
                            3. VIP 观赛权益：赛季结束后 30 天内安排行程
                        </div>
                    </div>
                    <div class="rule-item">
                        <div class="rule-label">奖励发放规则</div>
                        <div class="rule-value">
                            1. 被推荐人需通过直接入金，第三方转账无效
                        </div>
                        <div class="rule-value">
                            2. 同一被推荐人多账号参赛仅计 1 次有效推荐
                        </div>
                        <div class="rule-value">
                            3. 获奖资格以有效激活账户为准，VIP观赛席位包含机票住宿，本活动最终解释权归EBC金融集团所有
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <page-footer></page-footer>
    </div>
</template>

<script>
import { create } from 'js-md5';

function calculateProgressPercentage() {
  // 定义时间范围（注意：月份是0-based，2月是1）
  const startDate = new Date(2025, 1, 22);  // 2025-02-22
  const endDate = new Date(2025, 4, 23);    // 2025-05-23
  const now = new Date(); // 当前时间

  // 边界情况处理
  if (now < startDate) return 0;   // 未开始
  if (now >= endDate) return 100;  // 已结束

  // 计算总时间差和已过时间差（毫秒）
  const totalDuration = endDate - startDate;
  const elapsedDuration = now - startDate;

  // 计算百分比并四舍五入到整数
  const percentage = Math.round((elapsedDuration / totalDuration) * 100);

  // 确保结果在0-100范围内（理论上不需要，但防止浮点误差）
  return Math.min(100, Math.max(0, percentage));
}

export default {
    data() {
        return {
            activiedIndex:0
        }
    },
    methods:{
        handleSelect(index){
            this.activiedIndex= index;
        }
    },
    mounted(){
       let v = calculateProgressPercentage();
        document.getElementsByClassName("section3-line-value")[0].style.width = v+'%'
    }
}

</script>

<style scoped>
.body-bg {
    background-color: #000;
    background-image: url("../assets/image/win/bg.png");
    background-size: 100% auto;
    background-repeat: repeat-y;
}

.section1 {
    position: relative;
    height: 691rem;
    background: #000;
}

.section2,
.section3,
.section4,
.section5 {
    padding: 100rem 340rem;
}

.section1-bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
    background-image: url("../assets/image/refer-friends/bg1.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.section1-logo {
    height: 72rem;
}

.section1 h1 {
    /* display: flex;
    justify-content: center;
    align-items: baseline; */
    margin-top: 51rem;
    color: #fff;
    text-align: center;
    text-shadow: 0px 3.034px 3.034px rgba(0, 0, 0, 0.45);
    font-size: 53rem;
    font-weight: 900;
    line-height: normal;

}

.section1 h1 .ebc {
    font-size: 72rem;
    font-family: "Financier Display";
    line-height: normal;
}

.section1 h3 {
    margin-top: 13rem;
    position: relative;
    color: #FFF;
    font-family: "Noto Sans SC";
    font-size: 26rem;
    font-weight: 500;
    line-height: normal;
    /* letter-spacing: 0.268px; */
}

.section1 h3 .ebc {
    font-family: Gilroy;
    font-size: 29rem;
}

.section1 h3::before {
    position: absolute;
    top: 19rem;
    left: -140rem;
    display: block;
    height: 1px;
    width: 130rem;
    background: linear-gradient(to right, transparent, white);
    content: " ";
}

.section1 h3::after {
    position: absolute;
    top: 19rem;
    right: -140rem;
    display: block;
    height: 1px;
    width: 130rem;
    background: linear-gradient(to left, transparent, white);
    content: " ";
}

.refer-friends-btn {
    margin-top: 60rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 56rem;
    min-width: 300rem;
    padding: 0 30rem;
    color: #FFF;
    font-size: 20rem;
    font-weight: 700;
    line-height: 1em;
    background: linear-gradient(270deg, #041FAC 29.76%, #8C1B42 69.75%);
    /* border: 1px solid transparent; */
    transition: backgroundi 0.3s;
}

.refer-friends-btn:hover {
    border: 1px solid #000;
    background: linear-gradient(270deg, #041a8a 29.76%, #711636 69.75%);
}

.section2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section2 h2 {
    margin-top: -46rem;
    color: #fff;
    text-align: center;
    font-size: 40rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.section2 h2 .number {
    font-family: "Financier Display";
    font-size: 47rem;
}

.section2-300 {
    height: 164rem;
}

.section2 .p1 {
    margin-top: 15rem;
    color: #FFF;
    text-align: center;
    font-size: 14rem;
    font-style: normal;
    font-weight: 500;
    line-height: 30rem;
    opacity: 0.7;
}

.title-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18rem;
}

.title-yezi {
    width: 41rem;
    height: 77rem;
}

.section4 .title-yezi {
    width: 29rem;
    height: 54rem;
}

.title-inner-box {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title-inner-box h2 {
    color: #fff;
    text-align: center;
    font-size: 40rem;
    font-weight: 900;
    line-height: normal;
}

.title-inner-box .p1 {
    position: relative;
    margin-top: 2rem;
    color: #fff;
    font-size: 20rem;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
}

.title-inner-box .p1::before {
    font-family: "iconfont" !important;
    content: "\e616";
    font-size: 18rem;
    color: #A52933;

}

.title-inner-box .p1::after {
    font-family: "iconfont" !important;
    content: "\e616";
    font-size: 18rem;
    color: #114291;

}

.section3-card-list {
    margin-top: 40rem;
    display: flex;
    justify-content: center;

}

.section3-card-bg {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 447rem;
    height: 447rem;
    padding: 10rem 10rem;
    /* cursor: pointer; */
}

.section3-card-bg:hover {
    background: url("../assets/image/refer-friends/section3-card-box.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.section3-card {
    width: 100%;
    height: 100%;
    padding: 205rem 20rem 0;
}

.section3-card1 {
    background: url("../assets/image/refer-friends/section3-card-bg1.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.section3-card2 {
    background: url("../assets/image/refer-friends/section3-card-bg2.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.section3-card3 {
    background: url("../assets/image/refer-friends/section3-card-bg3.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}


.section3-card h3 {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 8rem;
    align-items: center;
    color: #fff;
    text-align: center;
    font-size: 36rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1em;
}

.section3-card h3::before {
    font-family: "iconfont" !important;
    content: "\e605";
    font-size: 20rem;
    color: #A52933;

}

.section3-card h3::after {
    font-family: "iconfont" !important;
    content: "\e605";
    font-size: 20rem;
    color: #114291;

}

.section3-card .p1 {
    margin-top: 24rem;
    color: #fff;
    text-align: center;
    font-size: 20rem;
    font-style: normal;
    font-weight: 400;
    line-height: 30rem;
}

.section3-card .p2 {
    margin-top: 2rem;
    color: #fff;
    text-align: center;
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.7;
}

.section3-line-list {
    /* width: 1125rem; */
    margin: 0 auto;
}

.section3-line-box {}

.section3-line-title-box {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.section3-line-count,
.section3-line-title {
    color: #FFF;
    font-size: 20rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.section3-line-bg-box {
    margin-top: 6rem;
    position: relative;
    height: 36rem;
    width: 100%;
    background: #232222;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.section3-line-time-box {
    display: flex;
    justify-content: space-between;
    padding: 0 16rem;
    transform: translateY(-35rem);
}

.section3-line-value {
    color: #FFF;
    font-family: Gilroy;
    font-size: 16rem;
    font-weight: 500;
    line-height: 28rem;
    text-align: right;
    padding-right: 30rem;
    width: 30%;
    height: 100%;
    border-radius: 0px 14rem 14rem 0px;
    background: linear-gradient(270deg, #041FAC 29.76%, #8C1B42 69.75%);
}

.section3-end-time,
.section3-start-time {
    color: #fff;
    font-family: Gilroy;
    font-size: 20rem;
    font-style: normal;
    font-weight: 500;
    line-height: 36rem;
}

.section3-block-list {
    display: flex;
    gap: 32rem;
}

.section3-block-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20rem;
    flex-grow: 1;
    width: 340rem;
    height: 144rem;
    flex-shrink: 0;
    border: 1px solid #8C1B42;
    background: linear-gradient(180deg, #3D3C3C 0%, #161414 73.5%), #D9D9D9;
}

.section3-block-title {
    display: inline-flex;
    gap: 8rem;
    align-items: center;
    color: #fff;
    text-align: center;
    font-size: 36rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1em;
}

.section3-block-title::before {
    font-family: "iconfont" !important;
    content: "\e605";
    font-size: 20rem;
    color: #A52933;

}

.section3-block-title::after {
    font-family: "iconfont" !important;
    content: "\e605";
    font-size: 20rem;
    color: #114291;

}

.section3-block-p {
    color: #FFF;
    font-size: 20rem;
    font-style: normal;
    font-weight: 500;
    line-height: 25rem;
}


.section4-card-list {
    margin-top: 40rem;
    display: flex;
    justify-content: center;

}

.section4-card-bg {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 447rem;
    height: 447rem;
    padding: 10rem 10rem;
    /* cursor: pointer; */
}

.section4-card-bg:hover {
    background: url("../assets/image/refer-friends/section3-card-box.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.section4-card {
    width: 100%;
    height: 100%;
    padding: 205rem 20rem 0;
}

.section4-card1 {
    background: url("../assets/image/refer-friends/section4-card-bg1.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.section4-card2 {
    background: url("../assets/image/refer-friends/section4-card-bg2.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.section4-card3 {
    background: url("../assets/image/refer-friends/section4-card-bg3.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}


.section4-card h3 {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 8rem;
    align-items: center;
    color: #fff;
    text-align: center;
    font-size: 36rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1em;
}



.section4-card .p1 {
    margin-top: 24rem;
    color: #fff;
    text-align: center;
    font-size: 20rem;
    font-style: normal;
    font-weight: 400;
    line-height: 30rem;
}

.section4-card .p2 {
    margin-top: 2rem;
    color: #fff;
    text-align: center;
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.7;
}

.section4-card .p3 {
    margin-top: 15rem;
    color: #9F734C;
    text-align: center;
    font-size: 20rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.section4-card .p3::before {
    font-family: "iconfont" !important;
    content: "\e605";
    font-size: 20rem;
    color: #9F734C;

}

.section4-card .p3::after {
    font-family: "iconfont" !important;
    content: "\e605";
    font-size: 20rem;
    color: #9F734C;

}

.section4-table-box {
    margin-top: 76rem;
    border: 1.257px solid #8C1B42;
}

.section4-tabs {
    display: flex;
}


.section4-tab {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 33.33%;
    flex: 1;
    background: linear-gradient(180deg, #40403F 0%, #282726 85.5%);
    height: 65rem;
    color: rgba(255, 255, 255, 0.3);
    font-size: 24rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    cursor: pointer;
}

.section4-tabs .activied {
    color: rgba(255, 255, 255, 0.8);
    background: linear-gradient(180deg, #A52933 0%, #3F1013 100%);
}

.section4-table {
    display: flex;
    background: linear-gradient(180deg, #232222 0%, #000 94.07%);
    padding: 60rem 90rem;
    flex-direction: column;

}

.section4-table-week-list {
    display: flex;
    justify-content: space-between;
}


.section4-table-week-item {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section4-table-week-item:hover .section4-jp1{
    display: block;
}
.section4-table-week-item:hover .section4-jp2{
    display: none;
}

.section4-jp1 {
    display: none;
    width: 72rem;
    height: 72rem;
    margin-bottom: 12rem;
}
.section4-jp2{
    width: 72rem;
    height: 72rem;
    margin-bottom: 12rem;
}

.section4-table-week-item .p1 {
    color: #FFF;
    font-size: 20rem;
    font-style: normal;
    font-weight: 700;
    line-height: 142.5%;
}

.section4-table-week-item .p2 {
    color: #FFF;
    font-family: Gilroy;
    font-size: 20rem;
    font-style: normal;
    font-weight: 400;
    line-height: 142.5%;
}

.section4-tips {
    margin-top: 54rem;
    color: rgba(255, 255, 255, 0.3);
    font-size: 14rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.rule-box {
    margin-top: 50rem;
    display: flex;
    /* width: 100%; */
    padding: 42rem 32rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 14rem;
    border: 1px solid #8C1B42;
    background: linear-gradient(180deg, #232222 0%, #000 94.07%);
}

.rule-item {
    width: 100%;
    font-size: 16rem;
    color: #FFF;

}

.rule-item+.rule-item {
    border-top: 1px solid #333;
    padding-top: 14rem;
}

.rule-label {
    opacity: 0.9;
    font-style: normal;
    font-weight: 700;
    line-height: 20rem;
    margin-bottom: 10rem;
}

.rule-value {
    opacity: 0.7;
    font-style: normal;
    font-weight: 400;
    line-height: 24rem;
}
</style>